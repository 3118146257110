import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import OurService from "./pages/OurService/OurService";
import SpecialPackage from "./pages/SpecialPackage/SpecialPackage";
import SpecialPackageDetail from "./pages/SpecialPackageDetail/SpecialPackageDetail";
import Blog from "./pages/Blog/Blog";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import Package from "./pages/Package/Package";
import PackageDetail from "./pages/PackageDetail/PackageDetail";
import Experience from "./pages/Experience/Experience";
import ExperienceDetail from "./pages/ExperienceDetail/ExperienceDetail";
import Gallery from "./pages/Gallery/Gallery";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />}></Route>
          <Route path="/our-story" element={<AboutUs />}></Route>
          <Route path="/service" element={<OurService />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route
            exact
            path="/blog-detail/:blogID"
            element={<BlogDetail />}
          ></Route>
          <Route path="/package" element={<Package />}></Route>
          <Route
            exact
            path="/package-detail/:packageID"
            element={<PackageDetail />}
          ></Route>
          <Route path="/experience" element={<Experience />}></Route>
          <Route path="/special-package" element={<SpecialPackage />}></Route>
          <Route
              exact
              path="/special-package-detail/:blogID"
              element={<SpecialPackageDetail />}
          ></Route>
          <Route
            exact
            path="/experience-detail/:blogID"
            element={<ExperienceDetail />}
          ></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
        </Route>
      </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
// root.render(
//   <BrowserRouter>
//     <React.StrictMode>
//     </React.StrictMode>
//   </BrowserRouter>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
